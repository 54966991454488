<template>
  <div class="view-home">
    <div class="container">
      <div class="quiz-status my-4">
        <div class="card" v-show="countdown">
          <ul>
            <li class="time">
              {{ countdown }}
            </li>
            <li class="recording" v-if="quiz.proctoring">
              {{ $t('testing.recording') }}
            </li>
            <li class="interruption" v-if="quiz.proctoring">
              {{ $t('testing.interruption') }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container questions-wrap" v-if="!loading">
      <div class="question-title mb-3" v-if="quiz.questions[question_id - 1][`subject_id_${test_lang}`]">
        {{ quiz.questions[question_id - 1][`subject_id_${test_lang}`] }}
      </div>
      <div v-if="!strict_mode" class="question-title">
        {{ $t('testing.question-number', {number: question_id}) }}
      </div>
      <div v-if="strict_mode" class="question-title">
        {{ $t('testing.question-number-strict', {number: question_id, count: questions.length}) }}
      </div>
      <div class="row mt-3">
        <div class="col-12 order-2 order-md-1" :class="strict_mode ? 'cl-md-12' : 'col-md-8'">
          <div class="question-content ck-content">
            <div v-katex:auto v-html="question_title">
            </div>
          </div>
          <div v-if="question_file">
            <audio :src="`https://elbasyacademy.kz/uploads${question_file}`" controls></audio>
          </div>
          <form class="row">
            <!--
              Одиночный (radio) тип вопроса
            -->
            <div v-if="answer_type == 1"
                 class="px-3 d-flex justify-content-start align-items-start flex-column ck-content">
              <el-radio v-for="(answer) in question_answers" :key="answer.id" v-model="selected_answer_id[0]"
                        :label="answer.id" border class="col-12 col-md-auto mx-0 single-one">
                <div v-katex:auto v-html="answer['text_' + test_lang]"></div>
              </el-radio>
            </div>
            <!--
              Множественный (checkbox) тип вопроса
            -->
            <div v-if="answer_type == 2"
                 class="px-3 d-flex justify-content-start align-items-start flex-column ck-content">
              <el-checkbox-group v-for="(answer) in question_answers" :key="answer.id" v-model="selected_answer_id"
                                 class="col-12 col-sm-auto mx-0">
                <el-checkbox :label="answer.id" border>
                  <div v-katex:auto v-html="answer['text_' + test_lang]"></div>
                </el-checkbox>
              </el-checkbox-group>
            </div>
            <!--
              Тип вопроса: правда/ложь
            -->
            <div v-if="answer_type == 3"
                 class="px-3 d-flex justify-content-start align-items-start flex-column ck-content">
              <el-radio v-for="(answer) in question_answers" :key="answer.id" v-model="selected_answer_id[0]"
                        :label="answer.id" class="col-12 col-md-auto mx-0">{{ answer['text_' + test_lang] }}
              </el-radio>
            </div>
            <!--
              Свободный ответ: textarea
            -->
            <div v-if="answer_type == 4" class="col-12 ck-content">
              <el-form ref="form" class="col-12 col-md-auto">
                <el-form-item label="Ваш ответ:">
                  <el-input type="textarea" :value="textarea" v-model="textarea"></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div v-if="answer_type == 6" class="col-12 ck-content">
              <div ref="strWithInputs" v-html="question_answers[0][`text_${test_lang}`]"></div>
            </div>
            <div v-if="answer_type == 7" class="col-12 ck-content">
              <div ref="strWithSelect" v-html="questions[question_id - 1][`text_${test_lang}`]"></div>
            </div>

            <div v-if="answer_type == 11" class="col-12 ck-content">
              <el-form ref="form" class="col-12 col-md-auto">
                <el-form-item label="Ваш ответ:">
                  <el-input type="textarea" :value="textarea" v-model="textarea"></el-input>
                </el-form-item>
              </el-form>
            </div>
          </form>
        </div>
        <div v-if="!strict_mode" class="col-12 col-md-4 order-1">
          <div class="navigation">
            <div class="card">
              <h3>{{ $t('testing.navigation') }}</h3>
              <div class="navigation-wrap clearfix">
                <a @click="!strict_mode ? goTo(key) : null" class="cursor-pointer" v-for="(key) in questions.length"
                   :key="`question_${key}`"
                   :class="{'checked': saved_answers[key], 'active': ($route.params.id === key.toString()) }">{{
                    key
                  }}</a>
              </div>
              <div class="text-center">
                <a @click="openModal" class="btn btn-danger">{{ $t('testing.finish-button') }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-5">
        <div class="mx-auto">
          <a v-if="!strict_mode" @click="prevQuestion" class="btn btn-default"><i
              class="el-icon-arrow-left mr-2"></i>{{ $t('testing.back-button') }}</a>
          <el-button v-if="!lastQuestion" @click="nextQuestion" :loading="btnLoading" class="btn btn-primary">
            {{ $t('testing.next-button') }}
          </el-button>
          <el-button v-if="lastQuestion && strict_mode" @click="openModal" class="btn btn-danger">
            {{ $t('testing.finish-button') }}
          </el-button>
        </div>
      </div>
    </div>
    <Loader v-else/>
    <b-modal id="exit-modal" hide-footer hide-header>
      <div id="modal-wrapper">
        <div id="dialog">
          <button @click="$bvModal.hide('exit-modal')" type="button" class="close" data-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <img class="modal-warning-img mb-3" src="/images/profile/modal-warning.svg">
          <h3 class="mb-0">{{ modalTitle }}</h3>
          <p>{{ $t('testing.finish-modal-desc') }}</p>
          <div class="clearfix pt-3">
            <el-button @click="exit" :disabled="exitBtnLoading" class="btn btn-primary mx-2">
              {{ $t('testing.finish-modal-button') }}
            </el-button>
            <a @click="$bvModal.hide('exit-modal')"
               class="btn btn-outline-primary mx-2">{{ $t('testing.finish-modal-cancel') }}</a>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import 'katex/dist/katex.min.css';
import Loader from '../../components/Loader';
import Vue from 'vue';

export default {
  name: "Index",
  components: {
    Loader
  },
  data() {
    return {
      quiz: {},
      question_title: '',
      answer_type: 0,
      saved_answers: [],
      question_answers: [],
      question_file: null,
      question_id: this.$route.params.id,
      countdown: 0,
      questions: [],
      selected_answer_id: [],
      modalTitle: this.$t('testing.finish-modal-title-not-completed'),
      textarea: '',
      answers_word: [],
      quiz_token: '',
      test_lang: 'ru',
      lastQuestion: false,
      strict_mode: null,
      timerInterval: null,
      btnLoading: false,
      loading: true,
      windowUnloadType: window.innerWidth < 678 ? 'pagehide' : 'beforeunload',
      exitBtnLoading: false,
      remainingTimer: null,
      user_quiz_id: 0
    }
  },
  methods: {
    updateCheckedAnswers: function () {
      /*
       * Обновляем переменную saved_answers чтобы кнопки
       * отвеченных вопросов меняли цвет как в дизайне
       */
      let res = {};
      if (localStorage.getItem('saved-answers')) {
        let savedAnswers = localStorage.getItem('saved-answers');
        res = JSON.parse(savedAnswers)
        this.saved_answers = res;
        /*
         * Заменяем заголовок модального окна
         * если есть ответ на последний вопрос
         */
        res.forEach((val, key) => {
          if (key == this.questions.length) {
            this.modalTitle = this.$t('testing.finish-modal-title')
          }
        })
      }
    },
    async goTo(id) {
      if (id < 1 || id > this.questions.length) return
      const res = await this.saveAnswer();
      /*
       * Обновляем переменные
       */
      // if (res.body.status === 'success') {
      /*
      * Переходим на выбранную страницу если мы уже не на ней
      */
      let answered_questions_length = 0
      for (const item of this.questions) {
        if (item.already_answered === true || item.selected == undefined ? false : item.selected.length > 0) {
          answered_questions_length++
        }
      }
      if (id === this.quiz.questions.length || answered_questions_length === this.quiz.questions.length) {
        this.lastQuestion = true
      }
      this.question_id = id.toString();
      this.question_title = this.questions[this.question_id - 1]['text_' + this.test_lang] ?? ''
      this.answer_type = this.questions[this.question_id - 1]['answer_type_id'] ?? ''
      this.question_answers = this.questions[this.question_id - 1]['answers'] ?? ''
      this.question_file = this.questions[this.question_id - 1]['file'];
      if (this.answer_type === 7) {
        this.question_title = '<p>Заполните пробелы</p>'
      }
      if (!this.strict_mode) {
        this.$router.push('/question/' + id).catch(err => {});
      }
      // }
    },
    async saveAnswer() {
      if (this.selected_answer_id !== '') {
        const answer = (Array.isArray(this.selected_answer_id)) ? this.selected_answer_id : [this.selected_answer_id];
        const questionId = (this.questions[this.question_id - 1]['id']) ?? 0;
        let params = {};
        if (answer.length) {
          params = {
            question_id: questionId,
            answer_id: answer,
            answers: answer,
            answer_type_id: this.answer_type
          };
        }
        if (this.textarea !== '') {
          params = {
            question_id: questionId,
            answers: [this.textarea],
            answer_type_id: this.answer_type
          }
        }
        if (this.answers_word.length) {
          params = {
            question_id: questionId,
            answer_id: this.questions[this.question_id - 1].answers[0].id,
            answers: this.answers_word,
            answer_type_id: this.answer_type
          }
        }

        if (Object.getOwnPropertyNames(params).length !== 0) {
          this.btnLoading = true
          try {
            const response = await this.$http.post(API_ROOT + '/api/user-answer/' + this.quiz_token, params)
            this.sendRemaningTimer()
            let savedAnswers = [];
            if (localStorage.getItem('saved-answers')) {
              savedAnswers = JSON.parse(localStorage.getItem('saved-answers'));
            }
            if (this.answer_type === 1) {
              savedAnswers[this.$route.params.id] = params.answer_id;
            }
            if (this.answer_type === 2) {
              savedAnswers[this.$route.params.id] = params.answer_id;
            }
            if (this.answer_type === 3) {
              savedAnswers[this.$route.params.id] = params.answer_id;
            }
            if (this.answer_type === 4) {
              savedAnswers[this.$route.params.id] = params.answers;
            }
            if (this.answer_type === 6) {
              savedAnswers[this.$route.params.id] = this.answers_word
            }
            if (this.answer_type === 7) {
              savedAnswers[this.$route.params.id] = this.answers_word
            }
            if (this.answer_type === 11) {
              savedAnswers[this.$route.params.id] = params.answers;
            }
            localStorage.setItem('saved-answers', JSON.stringify(savedAnswers));
            this.updateCheckedAnswers();
            return response
          } catch (error) {
            console.log(error.status==404)
            if (error.status==404){
              window.removeEventListener(this.windowUnloadType, this.beforeUnloadRequest)
              window.location.href = '/completed';
            }
            if (!navigator.onLine) {
              Vue.toastr({
                message: 'Проверьте интернет-соединение.',
                description: 'Ответ может не сохраниться.',
                type: 'warning'
              })
            }
            return error
          } finally {
            this.btnLoading = false
          }
        }
      }
    },
    prevQuestion() {
      const current = this.$route.params.id;
      if (parseInt(current) < 2) {
        return;
      }
      const prevId = parseInt(current) - 1;
      this.goTo(prevId);
    },
    nextQuestion() {
      let current = this.$route.params.id;
      let nextId = parseInt(current) + 1;
      if (this.strict_mode) {
        if (!this.selected_answer_id.length) {
          Vue.toastr({
            message: 'Warning',
            description: 'Выберите ответ',
            type: 'warning'
          })
          return false
        }
        for (let i = parseInt(current); i < this.quiz.questions.length; i++) {
          if (!this.quiz.questions[i].already_answered) {
            current = i + 1
            this.$router.push({
              name: 'question',
              params: {
                id: `${current}`
              }
            });
            break;
          }
        }
        nextId = parseInt(current)
      }
      if (parseInt(current) > this.questions.length) {
        return;
      }
      this.goTo(nextId);
    },
    startTimer(duration) {
      let timer = duration, minutes, seconds;
      this.timerInterval = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        this.countdown = minutes + ":" + seconds;

        let savedTimer = (parseInt(minutes) * 60) + parseInt(seconds);
        localStorage.setItem('timer', savedTimer);

        if (--timer < 1) {
          this.saveAnswer();
          this.exit();
          // timer = duration;
        }
      }, 1000);
    },
    openModal() {
      if (this.strict_mode) {
        if (!this.selected_answer_id.length) {
          Vue.toastr({
            message: 'Warning',
            description: 'Выберите ответ',
            type: 'warning'
          })
          return false
        }
      }
      this.saveAnswer();
      this.$bvModal.show('exit-modal');
    },
    async exit() {
      try {
        this.exitBtnLoading = true
        clearInterval(this.timerInterval)
        // clearInterval(this.remainingTimer)
        const currentTime = localStorage.getItem('timer')
        const {time} = JSON.parse(localStorage.getItem('quiz'))
        const testFinishTime = time * 60 - currentTime
        const res = await this.$http.post(`${API_ROOT}/api/quiz/finish/${this.quiz_token}`, {
          remaining_time: testFinishTime
        })
        if (res.body.status !== 'success') {

          Vue.toastr({
            message: 'Error',
            description: res.body,
            type: 'error'
          })
        }
        this.user_quiz_id = res.body.data.user_quiz_id;
        /*
         * Если в ответе прилетели результаты, то их сохраняем в localStorage
         */
        if (res.body.data) {
          localStorage.setItem('results', JSON.stringify(res.body.data));
        }
        /*
         * Если в настройках теста включен пункт
         * "показать результаты", то переходим к страницу результатов
         */
        // this.$router.push({ name: 'results' });
        // let quizData = localStorage.getItem('quiz');
        // if (quizData){
        // if (localStorage.getItem('display_results') == 1){
        //   this.$router.push({ name: 'results' });
        // } else {
        //   window.removeEventListener(this.windowUnloadType, this.beforeUnloadRequest);
        //   window.location.href = `${window.CABINET_ROOT}/testing`
        // }]
        // } else {
        this.$router.push({name: 'completed'});
        // }
      } catch (e) {
        this.$bvModal.hide('exit-modal');
        if (!navigator.onLine) {
          Vue.toastr({
            message: 'Проверьте интернет-соединение.',
            description: 'Ответ может не сохраниться.',
            type: 'warning'
          })
        }
        else{
        Vue.toastr({
          message: 'Error',
          description: e.body.message,
          type: 'error'
        })}
      } finally {
        this.exitBtnLoading = false
      }
    },
    async beforeUnloadRequest(e) {
      e = e || window.event;
      let message = 'You sure you want exit ?'
      // For IE and Firefox prior to version 4
      let left_time = localStorage.getItem('timer');
      if (e) {
        e.returnValue = message;
      }
      try {
        await this.$http.get(`${window.API_ROOT}/api/quiz/${this.quiz_token}/user-left`, {
          params: {
            user_quiz_id: localStorage.getItem('user_quiz_id'),
            remaining_time: localStorage.getItem('timer')
          }
        })
      } catch (error) {
      }

      // For Safari
      return message;
    },
    sendRemaningTimer() {
       this.$http.get(`${window.API_ROOT}/api/quiz/${this.quiz_token}/user-left`, {
        params: {
          user_quiz_id: localStorage.getItem('user_quiz_id'),
          remaining_time: localStorage.getItem('timer')
        }
      })
    },
    loadSavedAnswers(e) {
      const mixedAnswers = []
      let answersFromStorage = []
      if (localStorage.getItem('saved-answers')) {
        answersFromStorage = JSON.parse(localStorage.getItem('saved-answers'))
      }
      mixedAnswers[0] = null
      for (let i = 0; i < this.questions.length; i++) {
        if (this.questions[i].answer_type_id === 6 || this.questions[i].answer_type_id === 7) {
          mixedAnswers[i + 1] = this.questions[i].user_answer_words ? this.questions[i].user_answer_words : answersFromStorage[i + 1] ? answersFromStorage[i + 1] : null
        } else if (this.questions[i].user_answer) {
          mixedAnswers[i + 1] = this.questions[i].user_answer ? this.questions[i].user_answer : answersFromStorage[i + 1] ? answersFromStorage[i + 1] : null
        } else {
          mixedAnswers[i + 1] = this.questions[i].selected.length ? this.questions[i].selected : answersFromStorage[i + 1] ? answersFromStorage[i + 1] : null
        }
      }
      localStorage.setItem('saved-answers', JSON.stringify(mixedAnswers))
    },
    eventsOnCustomInputs() {
      setTimeout(() => {
        const inputs = [...this.$refs.strWithInputs.querySelectorAll('input')]
        inputs.map(input => {
          const index = +input.dataset.model
          input.value = this.answers_word[index] ? this.answers_word[index] : ''
          input.addEventListener('input', (e) => {
            this.answers_word[index] = e.target.value
          })
        })
      }, 300)
    },
    eventsOnCustomSelects() {
      setTimeout(() => {
        const selects = [...this.$refs.strWithSelect.querySelectorAll('select')]
        selects.map(select => {
          const index = +select.dataset.model
          const value = this.answers_word[index] ? this.answers_word[index] : ''
          const options = [...select.querySelectorAll('option')]
          options.map(option => {
            option.innerText === value ? option.selected = true : null
          })
          select.addEventListener('change', (e) => {
            this.answers_word[index] = e.target.value
          })
        })
      }, 300)
    }
  },
  watch: {
    $route(to, from) {
      /*
       * Сохраняем выбранных ответов, чтобы их выделить когда юзер вернулся обратно
       */
      this.selected_answer_id = [];
      this.textarea = '';
      this.answers_word = []
      if (localStorage.getItem('saved-answers')) {
        let savedAnswers = JSON.parse(localStorage.getItem('saved-answers'));
        if (savedAnswers[to.params.id]) {
          switch (this.answer_type) {
            case 1:
              this.selected_answer_id = savedAnswers[to.params.id][0];
              break;
            case 2:
              this.selected_answer_id = savedAnswers[to.params.id];
              break;
            case 3:
              this.selected_answer_id = savedAnswers[to.params.id][0];
              break;
            case 4:
              this.textarea = savedAnswers[to.params.id][0];
              break;
            case 6:
              this.answers_word = savedAnswers[to.params.id]
              break;
            case 7:
              this.answers_word = savedAnswers[to.params.id]
              break;
            case 11:
              this.textarea = savedAnswers[to.params.id][0]
              break;
          }
        }
      }
      +this.$route.params.id === this.questions.length ? (this.lastQuestion = true) : (this.lastQuestion = false)
      if (this.questions[this.question_id - 1]) {
        // this.selected_answer_id = this.questions[this.question_id - 1]['selected'] ?? [];
        // if (!this.questions[this.question_id - 1]['selected']) {
        this.selected_answer_id = this.saved_answers[this.question_id] ?? []
        // }
      }
    },
    question_id() {
      if (this.answer_type === 6) {
        this.eventsOnCustomInputs()
      }
      if (this.answer_type === 7) {
        this.eventsOnCustomSelects()
      }
    }
  },
  beforeCreate() {
    if (localStorage.getItem('quiz')) {
      let quiz = localStorage.getItem('quiz');
      quiz = JSON.parse(quiz);
      if (localStorage.getItem('identification')) {
        let identification = localStorage.getItem('identification');
        identification = JSON.parse(identification);
        if (quiz.proctoring && !identification) {
          window.location.href = '/identification';
        }
      }
    } else {
      this.$router.push({name: 'not-found'})
    }
  },
  mounted: async function () {
    let res;
    res = await this.$http.get(window.window.API_ROOT + `/api/selected/${localStorage.getItem('user_quiz_id')}/${localStorage.getItem('quiz-token')}`)
      var questions = JSON.parse(localStorage.getItem('quiz')).questions;
      var savedAnswers = []
      savedAnswers.push(null)
      for (var i = 0; i < questions.length; i++) {
        Object.keys(questions[i]).forEach(n => n !== 'id' && delete questions[i][n])
      }
      for (var x = 0; x < questions.length; x++) {
        if (res.body.data[questions[x].id] != undefined) {
          savedAnswers.push([res.body.data[questions[x].id].answer_id])
        }
        else {
          savedAnswers.push(null)
        }

      }
      if(savedAnswers[1]==null){
        savedAnswers[1]=[null]
      }
      localStorage.setItem('saved-answers', JSON.stringify(savedAnswers))
      this.selected_answer_id = [];

      if (localStorage.getItem('quiz')) {
        if (localStorage.getItem('test_lang')) {
          this.test_lang = localStorage.getItem('test_lang');
          this.$i18n.locale = this.test_lang;
        }
        if (localStorage.getItem('quiz-token')) {
          this.quiz_token = localStorage.getItem('quiz-token');
        }
        const quiz = JSON.parse(localStorage.getItem('quiz'));
        if (quiz.strict_mode) {
          for (let i = 0; i < quiz.questions.length; i++) {
            if (!quiz.questions[i].already_answered) {
              if (this.$route.params.id != `${i + 1}`) {
                this.$router.push({
                  name: 'question',
                  params: {
                    id: `${i + 1}`
                  }
                });
                this.question_id = this.$route.params.id
              }
              break;
            }
          }
        }
        this.loading = false
        this.quiz = quiz;
        this.questions = quiz.questions;
        this.strict_mode = quiz.strict_mode
        let timer = 0;
        // this.countdown = ('0' + quiz.time).slice(-2) + ':00';
        // this.countdown = ('');
        timer = localStorage.getItem('timer');
        if (timer < 1) {
          this.exit()
          return
        }
        this.updateCheckedAnswers();
        this.startTimer(timer);
        this.question_title = this.questions[this.question_id - 1]['text_' + this.test_lang] ?? ''
        this.answer_type = this.questions[this.question_id - 1]['answer_type_id'] ?? '';
        this.question_answers = this.questions[this.question_id - 1]['answers'] ?? '';
        this.question_file = this.questions[this.question_id - 1]['file'];
        this.selected_answer_id = this.questions[this.question_id - 1]['selected'] ?? [];
        // this.loadSavedAnswers()
        // this.updateCheckedAnswers();
        if (this.answer_type === 6) {
          if (localStorage.getItem('saved-answers')) {
            let savedAnswers = JSON.parse(localStorage.getItem('saved-answers'));
            if (savedAnswers[this.$route.params.id]) {
              this.answers_word = savedAnswers[this.$route.params.id]
            }
          }
          this.eventsOnCustomInputs()
        }
        if (this.answer_type === 7) {
          this.question_title = '<p>Заполните пробелы</p>'
          if (localStorage.getItem('saved-answers')) {
            let savedAnswers = JSON.parse(localStorage.getItem('saved-answers'));
            if (savedAnswers[this.$route.params.id]) {
              this.answers_word = savedAnswers[this.$route.params.id]
            }
          }
          this.eventsOnCustomSelects()
        }
        if (!this.questions[this.question_id - 1]['selected']) {
          this.selected_answer_id = this.saved_answers[this.question_id]
        }
      }
      +this.$route.params.id === this.questions.length ? (this.lastQuestion = true) : (this.lastQuestion = false)
      window.addEventListener(this.windowUnloadType, this.beforeUnloadRequest)
      await this.goTo(1)

  },
  beforeDestroy() {
    window.removeEventListener(this.windowUnloadType, this.beforeUnloadRequest)
  },
}
</script>

<style>
.custom-answer-select {
  padding: 10px 35px 10px 15px;
  border: 1px solid #dfdfdf;
  appearance: none;
  border-radius: 6px;
  background-image: url('/images/down-filled-triangular-arrow.svg');
  background-size: 12px 12px;
  background-repeat: no-repeat;
  background-position: 90% 50%;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:hover {
  text-decoration: none;
}

p {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: inline-block;
}

.question-content p {
  font-size: 20px;
}

.el-radio__label p,
.el-checkbox__label p {
  margin: 0;
  font-size: 18px;
}

.el-radio__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 14px;
  white-space: normal;
}

.el-checkbox__input,
.el-radio__input {
  display: block;
  float: left;
  margin: 3px 0 0;
}

.el-radio.is-bordered {
  padding-bottom: 12px;
}

.row {
  direction: initial;
}

.el-checkbox.is-bordered,
.el-radio.is-bordered {
  max-height: fit-content;
  height: initial;
  min-height: inherit;
  border: transparent;
}

@supports (-webkit-touch-callout: none) {
  .el-checkbox.is-bordered,
  .el-radio.is-bordered {
    max-height: fit-content;
    min-height: 40px;
  }

  .row {
    display: initial;
  }
}

.el-checkbox.is-checked,
.el-radio.is-checked {
  background: #fff;
  border: transparent;
}

@media (max-width: 500px) {
  .el-checkbox.is-bordered,
  .el-radio.is-bordered {
    flex: auto;
  }
}
</style>
